import React from "react";
import ReactWOW from "react-wow";
import client1 from "../../assets/images/team/team5.jpg";
import client2 from "../../assets/images/team/team7.jpg";
import shape from "../../assets/images/shape/shape1.svg";

const ContactForm = () => {
	return (
		<section className="partner-area pt-10 pb-70 bg-f1f8fb">
			<div className="container">
				<br></br>
				<br></br>
				<div className="section-title">
					<h2>Newsletter, Instgram et Podcast</h2>
					<span className="sub-title">
						De quoi nourrir tes projets de vie pro.
					</span>
				</div>

				<div className="single-testimonials-item">
					<p>
						Chaque mardi, à 8h45, on t’envoie directement, dans ta
						boite-mail, de quoi nourrir tes projets de vie pro.
						L’aventure ne s’arrête pas ici : tu peux nous retrouver
						tous les jours sur Instagram, c’est là qu’on fait vivre
						notre communauté.
					</p>

					<div className="client-info">
						<div className="d-flex justify-content-center align-items-center">
							<img src={client1} alt="testimonial" />
							<img src={client2} alt="testimonial" />

							<div className="title">
								<h3>
									{" "}
									Jasmine et Carla, les co-fondatrices de
									Vocation!
								</h3>
								<span>
									24 ans et tout juste diplômées d’école de
									commerce.
								</span>
							</div>
						</div>
					</div>
				</div>

				<div className="testimonials-view-btn text-center">
					<div className="default-btn">
						<h3>
							Fiches métier, inspiration, ressources, opportunités
							et événements…{" "}
						</h3>
						<span></span>
					</div>
				</div>
			</div>

			<div className="shape-img1">
				<img src={shape} alt="testimonial" />
			</div>

			<br></br>
			<br></br>

			<div className="container">
				<div className="about-content text-center">
					<ReactWOW delay=".1s" animation="fadeInLeft">
						<h2>Cette NEWSLETTER est faite pour toi</h2>
						<p>
							Si tu accordes de l’importance à ta carrière et à
							ton futur pro, mais que tu te sens perdu (voire
							anxieux) sur comment l’aborder. Chaque dimanche, à
							18 heures, Le PODCAST qui explore les grandes
							questions du monde professionnel.
						</p>
					</ReactWOW>
				</div>

				<br></br>

				<div className="row">
					<div className="col-lg-4 col-md-12">
						<div className="contact-image">
							<img
								src="https://cdn.substack.com/image/fetch/w_170,c_limit,f_auto,q_auto:best,fl_progressive:steep/https%3A%2F%2Fbucketeer-e05bbc84-baa3-437e-9518-adb32be77984.s3.amazonaws.com%2Fpublic%2Fimages%2F32079cfc-fb4f-4e77-9f2f-47a51360af09_1280x1280.png"
								alt="Logo"
								className="publication-logo"
							/>
						</div>
					</div>

					<div className="col-lg-6 col-md-12">
						<div className="contact-form">
							<form id="contactForm">
								<div className="row">
									<div className="col-lg-12 col-md-6">
										<div className="form-group">
											<input
												type="email"
												name="email"
												className="form-control"
												required
												placeholder="Votre email"
											/>
										</div>
									</div>

									<div className="col-lg-12 col-md-12">
										<button
											type="submit"
											className="default-btn"
										>
											<i className="flaticon-tick"></i>
											ENVOYER <span></span>
										</button>
										<p>
											On essaie de faire de cet e-mail
											l’un des meilleurs que tu reçois
											chaque semaine.
										</p>
									</div>
								</div>
							</form>
						</div>
					</div>
					<br></br>
					<br></br>
				</div>
			</div>
		</section>
	);
};

export default ContactForm;
